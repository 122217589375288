
import { defineComponent, onMounted } from "vue";
import {
  setCurrentPageBreadcrumbs,
  setCurrentPageTitle,
} from "@/core/helpers/breadcrumb";
import Entitytable from "@/components/EntityTable.vue";
import moment from "moment";

export default defineComponent({
  name: "kt-subscription-list",
  components: {
    Entitytable,
  },
  methods: {
    getDate(date) {
      return moment(date).format("YYYY-MM-DD hh:mm:ss");
    },
  },
  data() {
    return {
      columnData: [
        {
          label: "ID",
          name: "id",
        },
        {
          label: "Name",
          name: "name",
        },
        {
          label: "Creation Date",
          name: "created_at",
        },
        {
          label: "Affiliate",
          name: "affiliate",
        },
        {
          label: "Moderator",
          name: "moderator",
        },
      ],
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Subscription List", ["Apps", "Subscriptions"]);
      setCurrentPageTitle("Users");
    });

    return {};
  },
});
