import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-toolbar" }
const _hoisted_4 = { class: "card-body pt-0" }
const _hoisted_5 = { class: "icon-success fs-3" }
const _hoisted_6 = {
  key: 0,
  class: "bi bi-check-circle-fill text-success"
}
const _hoisted_7 = {
  key: 1,
  class: "bi bi-x-circle-fill text-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Entitytable = _resolveComponent("Entitytable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          to: { name: 'user-new' },
          class: "btn btn-primary"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Add new user")
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_Entitytable, {
        "entity-name": "user",
        "route-prefix": "user",
        "resource-name": "users",
        "show-actions": true,
        "link-view": true,
        "show-create": true,
        "show-edit": true,
        "show-delete": true,
        "override-columns": _ctx.columnData
      }, {
        "column-created_at": _withCtx((user) => [
          _createTextVNode(_toDisplayString(_ctx.getDate(user.entity.created_at)), 1)
        ]),
        "column-moderator": _withCtx((user) => [
          _createElementVNode("div", _hoisted_5, [
            (user.entity.moderator)
              ? (_openBlock(), _createElementBlock("i", _hoisted_6))
              : (_openBlock(), _createElementBlock("i", _hoisted_7))
          ])
        ]),
        _: 1
      }, 8, ["override-columns"])
    ])
  ]))
}